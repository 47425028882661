import Vue from 'vue';

export function getUploadMediaUrl() {
  return `${process.env.VUE_APP_API_LOCATION}/media/upload`;
}

export async function uploadMedia(data) {
  return Vue.$http({
    url: '/media/upload',
    method: 'post',
    data,
  });
}
