import { isPlainObject } from 'lodash';

/**
 * @param {object} orgObj
 * @param {object} newObj
 * @param {object} updObj
 * @returns {object}
 */
export function makeUpdateObj(orgObj, newObj, updObj = {}) {
  Object.keys(newObj).forEach((key) => {
    if (isPlainObject(newObj[key]) && isPlainObject(orgObj[key])) {
      const childObj = makeUpdateObj(orgObj[key], newObj[key]);
      if (Object.keys(childObj).length > 0) {
        // eslint-disable-next-line no-param-reassign
        updObj[key] = childObj;
      }
    } else if (
      (orgObj[key] === undefined
        && typeof newObj[key] === 'string'
        && newObj[key].trim() !== '')
      || (orgObj[key] !== undefined && newObj[key] !== orgObj[key])
    ) {
      // eslint-disable-next-line no-param-reassign
      updObj[key] = newObj[key];
    }
  });

  return updObj;
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  // eslint-disable-next-line vue/max-len
  const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}
