<template>
  <div>
    <el-upload
      class="media-uploader"
      :show-file-list="false"
      :action="uploadMediaUrl"
      :headers="uploadHeaders"
      :data="uploadData"
      :multiple="false"
      :thumbnail-mode="false"
      :on-success="handleSuccess"
      :before-upload="beforeUpload"
      list-type="picture-card"
    >
      <div v-if="uploadedImageUrl || imageUrl">
        <img
          :src="uploadedImageUrl || imageUrl"
          class="uploaded-media"
          alt=""
        />
        <i
          class="el-icon-delete media-uploader-icon"
        ></i>
      </div>
      <i
        v-else
        class="el-icon-plus media-uploader-icon"
      ></i>
    </el-upload>
  </div>
</template>

<script>
import { getUploadMediaUrl } from '@/api/media';
import { getToken } from '@/utils/auth';

export default {
  name: 'UploadMedia',
  props: {
    imageUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      uploadedImageUrl: null,
    };
  },
  computed: {
    uploadMediaUrl() {
      return getUploadMediaUrl();
    },
    uploadHeaders() {
      const token = getToken();

      return {
        authorization: `Bearer ${token}`,
      };
    },
    uploadData() {
      return {};
    },
  },
  methods: {
    reset() {
      this.uploadedImageUrl = null;
    },
    handleSuccess(res, file) {
      this.uploadedImageUrl = URL.createObjectURL(file.raw);

      if (res.media) {
        this.$emit('success', res.media);
      }
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      let isImage = true;

      if (!['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
        isImage = false;

        this.$message.error('Allowed image formats: JPG, PNG, WEBP');
      }

      if (!isLt10M) {
        this.$message.error('Image size can not exceed 10MB');
      }

      return isImage && isLt10M;
    },
  },
};
</script>

<style>
.media-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.media-uploader .el-upload:hover {
  border-color: #409eff;
}

.media-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}

.uploaded-media::before {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
}

.uploaded-media {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  vertical-align: middle;
}
</style>
