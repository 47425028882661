import debounce from 'lodash/debounce';

export default {
  mounted() {
    this.handleDebouncedResize = debounce(this.maxHeightMethod, 50);

    window.addEventListener('resize', this.handleDebouncedResize);

    this.maxHeightMethod();
  },
  data() {
    return {
      maxHeight: null,
    };
  },
  methods: {
    maxHeightMethod() {
      if (window.innerWidth <= 768) {
        this.maxHeight = '';
        return;
      }
      const windowHeight = window.innerHeight;
      const filters = Array.from(document.getElementsByClassName('filter-container'));
      const headerHeight = document.getElementsByClassName('fixed-header')[0]?.offsetHeight;
      const paginationHeight = (document.getElementsByClassName('el-pagination')[0]?.offsetHeight || 73);
      const clearBtnHeight = (document.getElementsByClassName('margin-top-20')[0]?.offsetHeight || 0);
      const buttonsHeight = (document.getElementsByClassName('buttons-container')[0]?.offsetHeight || 0);
      const tabsHeight = (document.getElementsByClassName('el-tabs')[0]?.offsetHeight || 0);
      const menubars = Array.from(document.getElementsByClassName('el-menu-body'));
      let menubarsHeight;
      let filtersHeight;

      if (filters.length === 0) {
        filtersHeight = 0;
      } else if (filters.length === 1) {
        filtersHeight = document.getElementsByClassName('filter-container')[0].offsetHeight;
      } else {
        filtersHeight = filters.length > 0 ? filters.reduce(
          (prevItem, currentItem) => prevItem.offsetHeight + currentItem.offsetHeight,
        ) : 0;
      }

      if (menubars.length === 0) {
        menubarsHeight = 0;
      } else if (menubars.length === 1) {
        menubarsHeight = document.getElementsByClassName('el-menu-body')[0].offsetHeight;
      } else {
        menubarsHeight = menubars.length > 0 ? menubars.reduce(
          (prevItem, currentItem) => prevItem.offsetHeight + currentItem.offsetHeight,
        ) : 0;
      }

      this.$nextTick(() => {
        this.maxHeight = windowHeight - filtersHeight - buttonsHeight - menubarsHeight - tabsHeight
          - headerHeight - paginationHeight - clearBtnHeight - 90;
      });
    },
  },
};
