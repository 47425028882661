export default {
  methods: {
    linkView(linkName, uid, event) {
      if ((event && event.ctrlKey) || (event && event.metaKey)) {
        const routeData = this.$router.resolve({ name: linkName, params: { id: uid } });
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({ name: linkName, params: { id: uid } });
      }
    },
  },
};
